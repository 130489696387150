export enum Technology {
  ANGULAR = 'Angular',
  BABEL = 'Babel',
  CORDOVA = 'Apache Cordova',
  CSS = 'CSS',
  DART = 'Dart',
  ENZYME = 'Enzyme',
  FIGMA = 'Figma',
  FLUTTER = 'Flutter',
  GIT = 'Git',
  GRAPHQL = 'GraphQL',
  GULP = 'Gulp',
  HTML = 'HTML',
  JAVA = 'Java',
  JAVASCRIPT = 'JavaScript',
  JEST = 'Jest',
  IONIC = 'Ionic Framework',
  KOTLIN = 'Kotlin',
  LESS = 'Less',
  NEXT_JS = 'Next.js',
  PHOTOSHOP = 'Photoshop',
  POSTMAN = 'Postman',
  REACT = 'React',
  REACT_ROUTER = 'React Router',
  REDUX = 'Redux',
  SASS = 'SASS',
  SQL = 'SQL',
  STORYBOOK = 'Storybook',
  STYLEGUIDIST = 'Styleguidist',
  SVELTE = 'Svelte',
  SWAGGER = 'Swagger',
  TYPESCRIPT = 'TypeScript',
  VUE = 'Vue.js',
  WEBPACK = 'Webpack',
  ZEPLIN = 'Zeplin'
}
